<template>
  <SearchResultsSection
    :show-clear-button="showClearButton"
    :title="title"
    :is-inline="isInline"
    @clear="clearSearch"
  >
    <div :class="{ 'inline-searches': isInline }">
      <SmartLink
        v-for="item in items"
        :key="item.id"
        :to="slugFor(item.slug)"
        :native="false"
        :class="{ 'inline-search': isInline }"
        data-analytics-id="search-suggestion"
        :data-analytics-label="itemType"
        @click="saveSearch(item)"
      >
        <component :is="isInline ? 'div' : 'p'">
          <template v-if="highlightText">
            <TextWithHighlight
              :full-text="nameFor(item)"
              :highlighted-text="queryText"
            />
          </template>
          <template v-else>
            {{ nameFor(item) }}
          </template>
        </component>
      </SmartLink>
    </div>
  </SearchResultsSection>
</template>

<script>
import SearchResultsSection from '@sephora-asia/core-search-results-section/SearchResultsSection.vue'
import TextWithHighlight from '@sephora-asia/core-text-with-highlight/TextWithHighlight.vue'

export default {
  name: 'SearchResultsItem',

  components: {
    SearchResultsSection,
    TextWithHighlight
  },

  props: {
    appendQuery: {
      type: Boolean,
      default: false
    },
    highlightText: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    queryText: {
      type: String,
      default: ''
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    itemType: {
      type: String,
      default: ''
    }
  },

  methods: {
    clearSearch() {
      this.$emit('clear-search')
    },
    nameFor(item) {
      if (item.type === 'categories' && item.parentName) {
        return (
          item.name +
          ` ${this.$t('layout.searchResult.under')} ` +
          item.parentName
        )
      } else {
        return item.name
      }
    },
    saveSearch(item) {
      this.$emit('toggle-active', false)
      this.$emit('track-clicked-item', item.slug)
      this.$emit('save-recent-search', {
        name: item.name,
        slug: this.slugFor(item.slug),
        type: item.type
      })
    },
    slugFor(slug) {
      if (this.appendQuery) {
        return this.slugWithQuery(slug)
      } else {
        return slug
      }
    },
    slugWithQuery(slug) {
      if (this.queryText.length > 1) {
        return `${slug}?q=${encodeURI(this.queryText)}`
      } else {
        return slug
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-searches {
  flex: auto;

  .inline-search {
    margin-left: 10px;

    div {
      display: inline;
    }
  }
}
</style>
