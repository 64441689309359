<template>
  <div class="sign-in-banner row">
    <div class="sign-in-banner-left">
      {{ $t('sitewideBanner.leftMessage') }}
    </div>
    <div
      class="sign-in-banner-mid"
      @mouseover="pauseScroll = true"
      @mouseleave="pauseScroll = false"
    >
      <div class="text-scroll">
        <ul class="list" :class="pauseScroll ? 'pause-scroll' : 'start-scroll'">
          <!-- eslint-disable vue/no-v-html -->
          <li v-for="item in items" :key="item" class="item" v-html="item" />
          <!-- eslint-enable -->
          <li class="item">
            <SmartLink :native="false" to="/beauty-pass" class="beauty-pass">
              {{ $t('sitewideBanner.andMoreText') }}
            </SmartLink>
          </li>
        </ul>
      </div>
    </div>
    <div class="sign-in-banner-right">
      <Button
        :label="$t('dictionary.signIn')"
        url="/sign_in"
        type="btn-default"
        size="btn-sm"
        class="btn"
        data-analytics-id="homepage-button"
      />
      <Button
        :label="$t('dictionary.signUp')"
        url="/sign_in"
        type="btn-white"
        size="btn-sm"
        class="btn"
        data-analytics-id="homepage-button"
      />
    </div>
  </div>
</template>

<script>
import Button from '@sephora-asia/core-button/Button.vue'

export default {
  name: 'SignInBanner',

  components: {
    Button
  },

  data() {
    return {
      items: this.$t('sitewideBanner.midBannerList').split('||'),
      pauseScroll: false
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-in-banner {
  background: linear-gradient(270deg, #ffbda9 0%, #ff7e67 100%);
  // Temporarily keeping these colors as hardcoded hexcode for BBDY as these are not available in our list
  color: $white;
  font-size: $size-typography-size-l;
  margin: auto;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  @include tablet {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sign-in-banner-left {
    text-transform: uppercase;
    text-align: center;
    font-weight: $size-typography-weight-medium;
    font-size: $size-typography-size-m;
    display: flex;
    align-items: center;
  }

  .sign-in-banner-mid {
    flex: 1 auto;
  }

  .sign-in-banner-right {
    display: flex;
    gap: 5px;

    @include tablet {
      gap: 10px;

      .btn {
        width: 150px;
      }
    }
  }

  .text-scroll {
    margin: auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    height: 45px;
  }

  .list {
    font-weight: normal;
    overflow: hidden;
    margin: 4px 0 0;
    transform: translateY(8%);
    animation: scrollUp 20s linear infinite;

    li {
      padding: 12px 10px;
      list-style: none;
    }
  }

  .pause-scroll {
    animation-play-state: paused;
  }

  .start-scroll {
    animation-play-state: running;
  }

  a.beauty-pass {
    text-decoration: underline;
    color: $white;
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(-92%);
  }
}
</style>
