import { render, staticRenderFns } from "./TopNavigationDropdownMyAccount.vue?vue&type=template&id=b494fcd0&scoped=true&"
import script from "./TopNavigationDropdownMyAccount.vue?vue&type=script&lang=js&"
export * from "./TopNavigationDropdownMyAccount.vue?vue&type=script&lang=js&"
import style0 from "./TopNavigationDropdownMyAccount.vue?vue&type=style&index=0&id=b494fcd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b494fcd0",
  null
  
)

export default component.exports