<template>
  <Dropdown class="my-account-dropdown">
    <TopNavigationElement
      :text="$t('layout.navigation.myAccount')"
      :icon-src="$options.locals.myAccountIcon"
      :additional-attrs="additionalAttrs"
      @click="click"
    />
    <template slot="content">
      <h6 class="dropdown-item dropdown-title">
        {{ $t('layout.navigation.greet', { name: userName }) }}
      </h6>
      <SmartLink
        v-for="link in accountLinks"
        :key="link.label"
        :native="false"
        :to="link.path"
        :class="[
          'dropdown-item is-capitalized',
          { 'beauty-profile-link-container': isBeautyProfilePath(link.path) }
        ]"
        data-analytics-id="my-account-link"
        :data-analytics-label="link.label"
      >
        {{ link.label }}
        <span v-if="isBeautyProfilePath(link.path)" class="new-tag">
          {{ $t('dictionary.new') }}
        </span>
      </SmartLink>
      <hr class="separator" />
      <a
        class="dropdown-item is-capitalized"
        data-analytics-id="my-account-link"
        data-analytics-label="Logout"
        tabindex="0"
        role="button"
        @click="signOut"
      >
        {{ $t('dictionary.logout') }}
      </a>
    </template>
  </Dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import MyAccountIcon from '~/assets/images/my-account.svg'

export default {
  name: 'TopNavigationDropdownMyAccount',

  components: {
    Dropdown,
    TopNavigationElement
  },

  data() {
    return {
      accountLinks: [
        { label: this.$t('dictionary.dashboard'), path: '/account/dashboard' },
        { label: this.$t('dictionary.orders'), path: '/account/orders' },
        {
          label: this.$store.getters?.['globalConfig/enableFullsize']
            ? this.$t('dictionary.getItAgain')
            : this.$t('dictionary.purchaseAgain'),
          path: this.$store.getters?.['globalConfig/enableFullsize']
            ? '/account/get-it-again'
            : '/account/purchased-items'
        },
        { label: this.$t('dictionary.reviews'), path: '/account/reviews' },
        { label: this.$t('dictionary.wishlist'), path: '/wishlist' },
        { label: this.$t('dictionary.waitlist'), path: '/waitlists' },
        ...(this.$store.getters?.['globalConfig/enableBeautyProfile']
          ? [this.beautyProfileLink()]
          : [])
      ]
    }
  },

  computed: {
    ...mapGetters({
      userName: 'user/name'
    }),
    additionalAttrs() {
      return {
        'data-analytics-id': 'header-click',
        'data-analytics-label': 'My Account'
      }
    }
  },

  methods: {
    click() {},
    signOut() {
      this.$authManager.signOut()
    },
    isBeautyProfilePath(path) {
      return path === '/account/beauty-profile'
    },
    beautyProfileLink() {
      return {
        label: this.$t('dictionary.beautyProfile'),
        path: '/account/beauty-profile'
      }
    }
  },

  locals: {
    myAccountIcon: MyAccountIcon
  }
}
</script>

<style lang="scss" scoped>
.separator {
  color: $grey;
  margin: 10px;
}

.new-tag {
  background-color: $red;
  color: $white;
  border-radius: $size-radius-low;
  font-size: $size-typography-size-xs;
  padding: 0 4px;
  margin: 3px 0 0 8px;
  text-transform: capitalize;
}

.beauty-profile-link-container {
  display: flex;
}
</style>
