<template>
  <SearchResultsSection
    :show-clear-button="showClearButton"
    :title="title"
    @clear="clearSearch"
  >
    <div class="columns is-multiline is-marginless product-card-panel">
      <template v-for="product in products">
        <div :key="product.id" class="column is-half">
          <SmartLink
            :native="false"
            :to="slugFor(product.slug)"
            data-analytics-id="search-suggestion"
            data-analytics-label="product"
            @click="saveSearch(product)"
          >
            <ProductCardCompact
              :brand-name="product.brandName"
              :img-src="product.imgSrc"
              :price="product.price"
              :product-name="product.name"
              :rating="product.rating"
            />
          </SmartLink>
        </div>
      </template>
    </div>
  </SearchResultsSection>
</template>

<script>
import ProductCardCompact from '@sephora-asia/core-product-card-compact/ProductCardCompact.vue'
import SearchResultsSection from '@sephora-asia/core-search-results-section/SearchResultsSection.vue'

export default {
  name: 'SearchResultsProduct',

  components: {
    ProductCardCompact,
    SearchResultsSection
  },

  props: {
    products: {
      type: Array,
      default: () => []
    },
    queryText: {
      type: String,
      default: ''
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },

  methods: {
    clearSearch() {
      this.$emit('clear-search')
    },
    saveSearch(product) {
      this.$emit('toggle-active', false)
      this.$emit('track-clicked-item', '/products/' + product.slug)
      this.$emit('save-recent-search', {
        name: `${product.brandName}, ${product.name}`,
        slug: this.slugFor(product.slug),
        type: product.type
      })
    },
    slugFor(slug) {
      return this.slugWithQuery('/products/' + slug)
    },
    slugWithQuery(slug) {
      if (this.queryText.length > 1) {
        return `${slug}?q=${encodeURI(this.queryText)}`
      } else {
        return slug
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-card-panel {
  margin-top: 5px;
}
</style>
