import SearchTransformer from '../../api/SearchTransformer.js'

export default () => ({
  namespaced: true,

  state: () => ({
    query: '',
    sort: ''
  }),

  mutations: {
    setQuery(state, string) {
      state.query = string
    },
    setSort(state, string) {
      state.sort = string
    }
  },

  actions: {
    async fetchProducts({ commit, state }, { params, language }) {
      commit('listingPageProducts/setListingPageProducts', [], {
        root: true
      })
      if (!params.query) return

      const requestParams = {
        ...params,
        'where[facets]':
          state.query === params.query && state.sort === params.sort
            ? 'false'
            : 'true'
      }

      const response = await this.$repositories.search.index(requestParams)
      const searchTransformer = new SearchTransformer(
        response.results.variants,
        language
      )
      const {
        products,
        brands,
        categories,
        meta,
        minPrice,
        maxPrice,
        hasUnderSaleProducts
      } = searchTransformer.transformedData
      meta.currentPage = params['page[number]']
      commit('listingPageProducts/setListingPageProducts', products, {
        root: true
      })
      commit('listingPageProducts/setListingPageProductsMeta', meta, {
        root: true
      })
      if (state.query !== params.query || state.sort !== params.sort) {
        commit('listingPageFilters/setListingPageBrandFilters', brands, {
          root: true
        })
        commit('listingPageFilters/setListingPageCategoryFilters', categories, {
          root: true
        })
        if (hasUnderSaleProducts) {
          commit(
            'listingPageFilters/setListingPageOtherFilters',
            [{ name: 'onSale', slugUrl: 'on_sale' }],
            { root: true }
          )
        }
        commit(
          'listingPageFilters/setListingPagePriceRangeFilter',
          { minPrice, maxPrice },
          { root: true }
        )
        commit('setQuery', params.query)
        commit('setSort', params.sort)
      }
    }
  }
})
