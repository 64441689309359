<template>
  <div>
    <template v-for="firstLevelmenuItem in menuItemData">
      <template v-if="firstLevelmenuItem.children.length > 0">
        <Accordion :key="firstLevelmenuItem.text" :initial-expanded="false">
          <SidebarNavItem
            :text="firstLevelmenuItem.text"
            :level="1"
            :has-separator="firstLevelmenuItem.hasSeparator"
            :icon-src="firstLevelmenuItem.iconSrc"
            :icon-alt-text="firstLevelmenuItem.text"
            :additional-attrs="firstLevelmenuItem.additionalAttrs"
          />
          <template #toggle-icon="slotProps">
            <span
              :class="slotProps.toggledClass"
              v-bind="firstLevelmenuItem.additionalAttrs"
            />
          </template>
          <template #content>
            <div>
              <template
                v-for="secondLevelmenuItem in firstLevelmenuItem.children"
              >
                <template v-if="secondLevelmenuItem.children.length > 0">
                  <Accordion
                    :key="secondLevelmenuItem.text"
                    :initial-expanded="false"
                  >
                    <SidebarNavItem
                      :key="secondLevelmenuItem.text"
                      :text="secondLevelmenuItem.text"
                      :level="2"
                      :has-separator="secondLevelmenuItem.hasSeparator"
                      :additional-attrs="secondLevelmenuItem.additionalAttrs"
                    />
                    <template #toggle-icon="slotProps">
                      <span
                        :class="slotProps.toggledClass"
                        v-bind="secondLevelmenuItem.additionalAttrs"
                      />
                    </template>
                    <template #content>
                      <div>
                        <template
                          v-for="thirdLevelmenuItem in secondLevelmenuItem.children"
                        >
                          <SmartLink
                            :key="thirdLevelmenuItem.id"
                            :to="thirdLevelmenuItem.url"
                          >
                            <span @click="$emit('toggle-sidebar-nav')">
                              <SidebarNavItem
                                :key="thirdLevelmenuItem.text"
                                :text="thirdLevelmenuItem.text"
                                :url="thirdLevelmenuItem.url"
                                :level="3"
                                :has-separator="thirdLevelmenuItem.hasSeparator"
                                :additional-attrs="
                                  thirdLevelmenuItem.additionalAttrs
                                "
                              />
                            </span>
                          </SmartLink>
                        </template>
                      </div>
                    </template>
                  </Accordion>
                </template>
                <template v-else>
                  <SmartLink
                    :key="secondLevelmenuItem.id"
                    :to="secondLevelmenuItem.url"
                    :native="!secondLevelmenuItem.isNonNativeLink"
                  >
                    <span @click="$emit('toggle-sidebar-nav')">
                      <SidebarNavItem
                        :key="secondLevelmenuItem.text"
                        :text="secondLevelmenuItem.text"
                        :description="secondLevelmenuItem.description"
                        :url="secondLevelmenuItem.url"
                        :level="2"
                        :has-separator="secondLevelmenuItem.hasSeparator"
                        :additional-attrs="secondLevelmenuItem.additionalAttrs"
                        :badge-text="badgeText(secondLevelmenuItem.url)"
                      />
                    </span>
                  </SmartLink>
                </template>
              </template>
            </div>
          </template>
        </Accordion>
      </template>
      <template v-else>
        <SidebarNavItem
          :key="firstLevelmenuItem.text"
          :text="firstLevelmenuItem.text"
          :url="firstLevelmenuItem.url"
          :level="1"
          :has-separator="firstLevelmenuItem.hasSeparator"
          :icon-src="firstLevelmenuItem.iconSrc"
          :icon-alt-text="firstLevelmenuItem.text"
          :additional-attrs="firstLevelmenuItem.additionalAttrs"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Accordion from '@sephora-asia/core-accordion/Accordion.vue'
import SidebarNavItem from '@sephora-asia/core-sidebar-nav-item/SidebarNavItem.vue'
import SmartLink from '@sephora-asia/common-ui/components/SmartLink.vue'

export default {
  name: 'SidebarNavMenuItems',

  components: {
    Accordion,
    SidebarNavItem,
    SmartLink
  },

  props: {
    menuItemData: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    badgeText(url) {
      return url === '/account/beauty-profile' ? this.$t('dictionary.new') : ''
    }
  }
}
</script>
